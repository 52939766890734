import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Header,
  Segment,
  Menu,
  Button,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as allProjectActions } from 'Redux/reducers/countproject'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as organizationsActions } from 'Redux/reducers/organizations'
import { DatePicker as DayPicker } from 'Utils'
import { ThaiDate } from 'Utils'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

var formatcid = cid => {
  if (cid && cid.length > 0) {
    return (
      cid[0] +
      '-' +
      cid[1] +
      cid[2] +
      cid[3] +
      cid[4] +
      '-' +
      cid[5] +
      cid[6] +
      cid[7] +
      cid[8] +
      cid[9] +
      '-' +
      cid[10] +
      cid[11] +
      '-' +
      cid[12]
    )
  } else {
    return ''
  }
}

class TableProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      next: 0,
      query: {
        // sort: { noAgreement: 1 },
        query: { delete: { $ne: true } },
        limit: 10000,
        skip: 0,
        projection: {
          nameTh: 1,
          recipientType: 1,
          recipientOrganizeName: 1,
          recipientPersonName: 1,
          recipientPerson: 1,
          recipientOrganize: 1,
          managerName: 1,
          budget: 1,
          codeProject: 1,
          codeBudget: 1,
          academic: 1,
          startDate: 1,
          endDate: 1,
          recDate: 1,
          status: 1,
          noAgreement: 1,
          dateContract: 1,
          typeOffice: 1,
          screen: 1,
          operationTime: 1,
          withDrawTime: 1,
          withdrawAccountName: 1,
          withdrawAccountNumber: 1,
          withdrawBankBranch: 1,
          withdrawBank: 1
        }
      },
      length: 0,
      nameTh: '',
      recipientOrganizeName: '',
      recipientPersonName: '',
      managerName: '',
      startDate: 0,
      endDate: 0,
      academic: '',
      checkQueryInvidual: false
    }
  }

  componentDidMount = () => {
    let { query } = this.state
    this.props.listProposal(query)
    this.props.allProject(query.query)
    this.props.listBudget()
    this.props.getOffice()
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.countProjects) {
      this.setState({ length: nextProps.countProjects / 10000 })
    }
    if (nextProps.projectsIn.length > 10 && !this.state.checkQueryInvidual) {
      this.setState({ checkQueryInvidual: true })
      let checkDupicate = []
      let arrayInvidual = { query: { $or: [] } }

      let checkDupicate2 = []
      let arrayOrganization = { query: { $or: [] } }

      nextProps.projectsIn.forEach(item => {
        if (item.recipientPerson || item.recipientOrganize) {
          if (item.recipientType === 'บุคคล')
            checkDupicate.push(item.recipientPerson)
          else checkDupicate2.push(item.recipientOrganize)
        }
      })
      checkDupicate = checkDupicate.filter(
        (item, index) => checkDupicate.indexOf(item) === index
      )
      checkDupicate2 = checkDupicate2.filter(
        (item, index) => checkDupicate2.indexOf(item) === index
      )
      checkDupicate.forEach(item => {
        arrayInvidual.query.$or.push({ _id: item })
      })
      checkDupicate2.forEach(item => {
        arrayOrganization.query.$or.push({ _id: item })
      })
      if (arrayInvidual.query.$or.length > 0)
        this.props.listRecipientPerson(arrayInvidual)
      if (arrayOrganization.query.$or.length > 0)
        this.props.listRecipientOrganization(arrayOrganization)
    }
  }

  format(input) {
    if (!input && input === 0) {
      input = ''
    }
    var num = input.replace(/,/g, '')
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.')
        num[0] = num[0]
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1)
        }
        input = num[0] + '.' + num[1]
        return input
      } else {
        input = num
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        return input
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1)
    }
  }

  handleSkipNext = () => {
    let { next, length, query } = this.state
    if (next < length - 1) {
      next = next + 1
      query.skip = 10000 * next
      this.props.listProposal(query)
      this.setState({ next, query, checkQueryInvidual: false })
    }
  }

  handleSkipBack = () => {
    let { next, query } = this.state
    if (next > 0) {
      next = next - 1
      query.skip = 10000 * next
      this.props.listProposal(query)
      this.setState({ next, query, checkQueryInvidual: false })
    }
  }

  handleSkipSelect = (e, { name }) => {
    let { next, query } = this.state
    next = parseInt(name)
    query.skip = 10000 * next
    this.props.listProposal(query)
    this.setState({ next, query, checkQueryInvidual: false })
  }

  handleDayChange = (name, index, date) => {
    let {
      query,
      startDate,
      endDate,
      recStartDate,
      recEndDate,
      startDateContract,
      endDateContract
    } = this.state
    if (name === 'startDate') {
      startDate = date._d.getTime()
      query.query.startDate = { $gte: startDate }
      this.setState({ query, startDate })
    } else if (name === 'endDate') {
      endDate = date._d.getTime()
      query.query.endDate = { $lte: endDate }
      this.setState({ query, endDate })
    } else if (name === 'rec-startDate') {
      if (!query.query.recDate) query.query.recDate = {}
      recStartDate = date._d.getTime()
      query.query.recDate.$gte = recStartDate
      this.setState({ query, recStartDate })
    } else if (name === 'rec-endDate') {
      if (!query.query.recDate) query.query.recDate = {}
      recEndDate = date._d.getTime()
      query.query.recDate.$lte = recEndDate
      this.setState({ query, recEndDate })
    } else if (name === 'startDateContract') {
      if (!query.query.dateContract) query.query.dateContract = {}
      startDateContract = date._d.getTime()
      query.query.dateContract.$gte = startDateContract
      this.setState({ query, startDateContract })
    } else if (name === 'endDateContract') {
      if (!query.query.dateContract) query.query.dateContract = {}
      endDateContract = date._d.getTime()
      query.query.dateContract.$lte = endDateContract
      this.setState({ query, endDateContract })
    }
  }

  handleChange = (e, { name, value }) => {
    let {
      query,
      nameTh,
      recipientOrganizeName,
      recipientPersonName,
      managerName,
      startDate,
      endDate,
      academic
    } = this.state

    if (
      name === 'budget' ||
      name === 'recipientType' ||
      name === 'typeOffice' ||
      name === 'yearBudget' ||
      name === 'status'
    ) {
      query.query[name] = value
      if (query.query.recipientType === 'บุคคล') {
        delete query.query.recipientOrganizeName
      }
      this.setState({ query })
    } else if (name === 'nameTh') {
      nameTh = value
      query.query.nameTh = { $regex: value }
      this.setState({ query, nameTh })
    } else if (name === 'managerName') {
      managerName = value
      query.query.managerName = { $regex: value }
      this.setState({ query, managerName })
    } else if (name === 'recipientOrganizeName') {
      recipientOrganizeName = value
      query.query.recipientOrganizeName = { $regex: value }
      this.setState({ query, recipientOrganizeName })
    } else if (name === 'recipientPersonName') {
      recipientPersonName = value
      query.query.recipientPersonName = { $regex: value }
      this.setState({ query, recipientPersonName })
    } else if (name === 'startDate') {
      startDate = value
      query.query.startDate = { $gte: value }
      this.setState({ query, startDate })
    } else if (name === 'endDate') {
      endDate = value
      query.query.endDate = { $lte: value }
      this.setState({ query, endDate })
    } else if (name === 'academic') {
      academic = value
      query.query.academic = { $regex: value }
      this.setState({ query, academic })
    }
  }

  handleSearch = () => {
    this.props.listProposal(this.state.query)
    this.props.allProject(this.state.query.query)
    this.setState({ checkQueryInvidual: false })
  }

  handleReset = () => {
    let { query } = this.state
    query = {
      // sort: { noAgreement: 1 },
      query: { delete: { $ne: true } },
      limit: 10000,
      skip: 0,
      projection: {
        nameTh: 1,
        recipientType: 1,
        recipientOrganizeName: 1,
        recipientPersonName: 1,
        recipientPerson: 1,
        recipientOrganize: 1,
        managerName: 1,
        budget: 1,
        codeProject: 1,
        codeBudget: 1,
        academic: 1,
        startDate: 1,
        endDate: 1,
        recDate: 1,
        status: 1,
        noAgreement: 1,
        dateContract: 1,
        screen: 1,
        operationTime: 1,
        withDrawTime: 1,
        withdrawAccountName: 1,
        withdrawAccountNumber: 1,
        withdrawBankBranch: 1,
        withdrawBank: 1
      }
    }
    this.props.listProposal(query)
    this.props.allProject(query.query)
    this.setState({
      query,
      nameTh: '',
      recipientOrganizeName: '',
      recipientPersonName: '',
      managerName: '',
      startDate: 0,
      endDate: 0,
      academic: '',
      next: 0
    })
  }

  filterOffice = code => {
    const { typeOffice } = this.props
    let typeOfficeOption = typeOffice[0]
      ? typeOffice[0].typeOffice.map((item, idx) => ({
        key: idx,
        text: item.name,
        value: item.value
      }))
      : []

    let text = typeOfficeOption.filter(x => x.value === code)
    if (text.length > 0) return text[0].text
    else return ''
  }

  render() {
    // let { projectsIn, fundings, typeOffice } = this.props
    let { projectsIn, typeOffice, individuals, organizations } = this.props
    const {
      next,
      length,
      query,
      // nameTh,
      recipientOrganizeName,
      recipientPersonName,
      // managerName,
      startDate,
      endDate,
      academic,
      recEndDate,
      recStartDate,
      startDateContract,
      endDateContract
    } = this.state
    let footer = []
    for (let i = 0; i < length; i++) {
      footer.push({})
    }

    // const yearOptions = fundings
    //   .filter(item => item.used === true)
    //   .map((item, idx) => ({
    //     name: item.year,
    //     key: item._id,
    //     value: item.year,
    //     text: item.year
    //   }))

    const typeProject = [
      { key: 0, text: 'องค์กร', value: 'องค์กร' },
      { key: 1, text: 'บุคคล', value: 'บุคคล' },
      { key: 2, text: 'คณะบุคคล', value: 'คณะบุคคล' }
    ]

    let typeOfficeOption = typeOffice[0]
      ? typeOffice[0].typeOffice.map((item, idx) => ({
        key: idx,
        text: item.name,
        value: item.value
      }))
      : []

    const statusProject = [
      { key: 0, text: 'ส่งข้อเสนอโครงการ', value: 'ส่งข้อเสนอโครงการ' },
      { key: 1, text: 'กลั่นกรอง', value: 'กลั่นกรอง' },
      { key: 2, text: 'ทำสัญญาโครงการ', value: 'ทำสัญญาโครงการ' },
      {
        key: 3,
        text: 'ประเมินความก้าวหน้าโครงการ',
        value: 'ประเมินความก้าวหน้าโครงการ'
      },
      { key: 4, text: 'ปิดโครงการ', value: 'ปิดโครงการ' },
      { key: 5, text: 'ยุติโครงการ', value: 'ยุติโครงการ' }
    ]

    projectsIn &&
      projectsIn.forEach((item, idx) => {
        let text = ''
        if (item.screen && item.screen.length > 0) {
          item.screen.forEach((i, index) => {
            if (i.nameProfessional && i.nameProfessional !== '') {
              if (index !== item.screen.length - 1)
                text += index + 1 + '. ' + i.nameProfessional + ',\r\n'
              else text += index + 1 + '. ' + i.nameProfessional
            }
          })
        }
        item.screentext = text
        let textList = ''
        let moneyList = ''
        if (item.withDrawTime && item.withDrawTime.length > 0) {
          item.withDrawTime.forEach((i, index) => {
            if (i.submitTime) {
              if (index !== item.withDrawTime.length - 1) {
                textList +=
                  index + 1 + '. ' + ThaiDate('month', i.submitTime) + '\r\n'
                moneyList += 'งวดที่ ' + (index + 1) + ' จำนวน ' + this.format(i.sum.toString())  + ' บาท\r\n'
              } else {
                textList += index + 1 + '. ' + ThaiDate('month', i.submitTime)
                moneyList += 'งวดที่ ' + (index + 1) + ' จำนวน ' + this.format(i.sum.toString()) + ' บาท'
              }
            }
          })
        }
        item.withDrawTimetext = textList
        item.withDrawTimeMonry = moneyList
      })

    return (
      <Segment>
        <Header
          as='h1'
          style={{ fontFamily: 'supermarket' }}
          content='รายละเอียดโครงการ'
          textAlign='center'
        />
        <Dimmer active={projectsIn.length === 0}>
          <Loader />
        </Dimmer>

        <Dimmer active={organizations.length === 0 && individuals.length === 0}>
          <Loader />
        </Dimmer>

        <Form>
          {/* <Form.Group>
            <Form.Select
              label='ปีงบประมาณ'
              options={yearOptions}
              width={4}
              name='yearBudget'
              onChange={this.handleChange}
              value={query.query.yearBudget}
            />
            <Form.Input
              label='ชื่อโครงการ'
              width={8}
              name='nameTh'
              onChange={this.handleChange}
              value={nameTh}
            />
            <Form.Input
              label='ชื่อผู้รับผิดชอบโครงการ'
              width={4}
              name='managerName'
              onChange={this.handleChange}
              value={managerName}
            />
          </Form.Group> */}
          <Form.Group>
            <Form.Select
              label='ประเภทผู้รับทุน'
              options={typeProject}
              width={4}
              name='recipientType'
              onChange={this.handleChange}
              value={query.query.recipientType}
            />
            <Form.Input
              label='ชื่อองค์กรรับทุน'
              width={8}
              name='recipientOrganizeName'
              onChange={this.handleChange}
              disabled={query.query.recipientType === 'บุคคล'}
              value={recipientOrganizeName}
            />
            <Form.Input
              label='ชื่อผู้รับทุน'
              width={4}
              name='recipientPersonName'
              onChange={this.handleChange}
              value={recipientPersonName}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label='นักวิชาการ/ผู้ปฏิบัติงาน'
              width={4}
              name='academic'
              onChange={this.handleChange}
              value={academic}
            />
            <Form.Select
              label='สังกัด/สำนัก'
              width={7}
              options={typeOfficeOption}
              name='typeOffice'
              onChange={this.handleChange}
              value={query.query.typeOffice}
            />
            <Form.Field style={{ fontFamily: 'supermarket' }}>
              <label>{'วันที่เริ่มต้นโครงการ'}</label>
              <DayPicker
                ser={'startDate'}
                name={'startDate'}
                max={endDate || ''}
                onDayChange={this.handleDayChange}
                data={startDate || ''}
              />
            </Form.Field>
            <Form.Field style={{ fontFamily: 'supermarket' }}>
              <label>{'วันที่สิ้นสุดโครงการ'}</label>
              <DayPicker
                ser={'endDate'}
                name={'endDate'}
                min={startDate || ''}
                onDayChange={this.handleDayChange}
                data={endDate || ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Select
              name='status'
              value={query.query.status}
              options={statusProject}
              onChange={this.handleChange}
              width={4}
              label='สถานะโครงการ'
            />

            <Form.Field style={{ fontFamily: 'supermarket' }}>
              <label>{'วันที่สร้างโครงการตั้งแต่'}</label>
              <DayPicker
                ser={'rec-startDate'}
                name={'rec-startDate'}
                max={recEndDate || ''}
                onDayChange={this.handleDayChange}
                data={recStartDate || ''}
              />
            </Form.Field>
            <Form.Field style={{ fontFamily: 'supermarket' }}>
              <label>{'ถึงวันที่'}</label>
              <DayPicker
                ser={'rec-endDate'}
                name={'rec-endDate'}
                min={recStartDate || ''}
                onDayChange={this.handleDayChange}
                data={recEndDate || ''}
              />
            </Form.Field>
            <Form.Field width={1} />
            <Form.Field style={{ fontFamily: 'supermarket' }}>
              <label>{'วันที่ทำสัญญาโครงการตั้งแต่'}</label>
              <DayPicker
                ser={'startDateContract'}
                name={'startDateContract'}
                max={endDateContract || ''}
                onDayChange={this.handleDayChange}
                data={startDateContract || ''}
              />
            </Form.Field>
            <Form.Field style={{ fontFamily: 'supermarket' }}>
              <label>{'ถึงวันที่'}</label>
              <DayPicker
                ser={'rec-endDateContract'}
                name={'endDateContract'}
                min={startDateContract || ''}
                onDayChange={this.handleDayChange}
                data={endDateContract || ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field style={{ textAlign: 'center' }}>
            <Button
              content='ล้างการค้นหา'
              icon='sync'
              size='big'
              onClick={this.handleReset}
              style={{ fontFamily: 'supermarket' }}
            />
            <Button
              color='green'
              content='ค้นหา'
              icon='search'
              size='big'
              onClick={this.handleSearch}
              style={{ fontFamily: 'supermarket' }}
            />
          </Form.Field>
          {projectsIn.length > 0 && (
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-csv-button'
              table='table-to-xls'
              filename='reportPMStablecsv'
              sheet='tablecsv'
              buttonText='Download as XLS'
            />
          )}
          <Segment
            style={{ overflow: 'auto scroll', width: '100%', height: '500px' }}
          >
            <pre>
              <table id='table-to-xls' border='1px black'>
                <thead>
                  <tr>
                    <th>ลำดับ</th>
                    <th>รหัสโครงการ</th>
                    <th>เลขที่สัญญา</th>
                    <th>ชื่อโครงการ</th>
                    <th>สถานะโครงการ</th>
                    <th>สำนัก/สังกัด</th>
                    <th>ภาคีร่วมดำเนินงาน/บุคคลรับทุน</th>
                    <th>รหัสประจำตัวผู้เสียภาษี</th>
                    <th>ผู้รับผิดชอบโครงการ</th>
                    <th>นักวิชาการ</th>
                    <th>งบประมาณ (บาท)</th>
                    <th>รหัสงบประมาณ</th>
                    <th>ผู้กลั่นกรองโครงการ</th>
                    <th>วันที่ทำสัญญาโครงการ</th>
                    <th>วันที่เริ่มต้นโครงการ</th>
                    <th>วันที่สิ้นสุดโครงการ</th>
                    <th>ระยะเวลาดำเนินงาน</th>
                    <th>จำนวนงวดงานงวดเงิน</th>
                    <th>จำนวนวงเงินของแต่ละงวดงานงวดเงิน</th>
                    <th>วันที่ส่งมอบผลงาน</th>
                    <th>ชื่อบัญชีธนาคาร</th>
                    <th>เลขที่บัญชีธนาคาร</th>
                    <th>ชื่อธนาคาร</th>
                    <th>ชื่อสาขาธนาคาร</th>
                    <th>วันที่สร้างโครงการเข้าระบบ</th>
                  </tr>
                </thead>
                <tbody>
                  {projectsIn.length > 0 &&
                    projectsIn.map((item, idx) => (
                      // <Fragment key={idx}>
                      <tr key={idx}>
                        <td>{next * 10000 + idx + 1}</td>
                        <td>{item.codeProject}</td>
                        <td>{item.noAgreement}</td>
                        <td>{item.nameTh}</td>
                        <td>{item.status}</td>
                        <td>{this.filterOffice(item.typeOffice)}</td>
                        {item.recipientType !== 'บุคคล' && (
                          <td>{item.recipientOrganizeName}</td>
                        )}
                        {item.recipientType === 'บุคคล' && (
                          <td>{item.recipientPersonName}</td>
                        )}
                        {item.recipientType !== 'บุคคล' && (
                          <td>
                            {organizations.length > 0 &&
                              organizations.filter(
                                itm => itm._id === item.recipientOrganize
                              )[0]
                              ? ' ' +
                              formatcid(
                                organizations.filter(
                                  itm => itm._id === item.recipientOrganize
                                )[0].cid || ''
                              )
                              : ''}
                          </td>
                        )}
                        {item.recipientType === 'บุคคล' && (
                          <td>
                            {individuals.length > 0 &&
                              individuals.filter(
                                itm => itm._id === item.recipientPerson
                              )[0]
                              ? ' ' +
                              formatcid(
                                individuals.filter(
                                  itm => itm._id === item.recipientPerson
                                )[0].cid || ''
                              )
                              : ''}
                          </td>
                        )}

                        <td>{item.managerName}</td>
                        <td>{item.academic}</td>
                        <td align='right'>
                          {this.format((item.budget || 0).toString())}
                        </td>
                        <td>{item.codeBudget}</td>
                        <td>{item.screentext}</td>
                        <td>{ThaiDate('month', item.dateContract)}</td>
                        <td>{ThaiDate('month', item.startDate)}</td>
                        <td>{ThaiDate('month', item.endDate)}</td>
                        <td>{item.operationTime}</td>
                        <td>
                          {item.withDrawTime ? item.withDrawTime.length : 0}
                        </td>
                        <td>
                          {item.withDrawTime ? item.withDrawTimeMonry : '-'}
                        </td>
                        <td>{item.withDrawTimetext}</td>
                        <td>{item.withdrawAccountName}</td>
                        <td>{item.withdrawAccountNumber}</td>
                        <td>{item.withdrawBank}</td>
                        <td>{item.withdrawBankBranch}</td>
                        <td>{ThaiDate('month', item.recDate)}</td>
                      </tr>
                      // </Fragment>
                    ))}
                </tbody>
              </table>
            </pre>
          </Segment>
          <Menu pagination>
            <Menu.Item as='a' content='<' onClick={this.handleSkipBack} />
            {footer.map((item, idx) => (
              <Fragment key={idx}>
                <Menu.Item
                  as='a'
                  content={idx + 1}
                  active={idx === next}
                  name={idx.toString()}
                  onClick={this.handleSkipSelect}
                />
              </Fragment>
            ))}
            <Menu.Item as='a' content='>' onClick={this.handleSkipNext} />
          </Menu>
        </Form>
      </Segment>
    )
  }
}
const mapStateToProps = state => ({
  projectsIn: state.projects.data,
  countProjects: state.countproject.data,
  auth: state.auth,
  role: state.role,
  fundings: state.fundings.data,
  typeOffice: state.typeproject.data,
  individuals: state.individuals.data,
  organizations: state.organizations.data
})

const mapDispatchToProps = dispatch => ({
  allProject: query => dispatch(allProjectActions.fetchList({ count: query })),
  listProposal: query => dispatch(projectActions.fetchList(query)),
  listBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: 1 } })),
  listRecipientPerson: query => dispatch(individualsActions.fetchList(query)),
  listRecipientOrganization: query =>
    dispatch(organizationsActions.fetchList(query)),
  getOffice: () =>
    dispatch(typeProject.fetchList({ query: { name: 'สังกัดสำนัก' } }))
})

export default connect(mapStateToProps, mapDispatchToProps)(TableProject)
